import React from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineDoubleRight } from 'react-icons/ai';
import gmb from '../../Assets/Services/GoogleBusiness.jpg';
import webdev from '../../Assets/Services/Website Development.jpg';
import appdev from '../../Assets/Services/Web_App.jpg';
import mobileapp from '../../Assets/Services/App Develeopment.jpg';
import ads from '../../Assets/Services/ADs.jpg';
import seo from '../../Assets/Services/Web_SEO.jpg';
import './Services.css';


const Services = () => {

  return (

    <div className="services">

      <div className="servicesTitile">
        <h1>OUR SERVICES</h1>
      </div>


      <div className="insuranceTypesContainer">
        <div className="insuranceType" >
          <div className="insuranceDesc">
            <Link to="gogle-my-business" className='link'><h3>Google My Business</h3></Link>
            <p>If you're seeking to enhance your online visibility and draw in a larger local customer base, then our Google My Business (GMB) services are the answer. Here at Instantly Scaled, we recognize the significance of standing out in local search outcomes and ensuring that potential clients can effortlessly locate your business. That is precisely why we provide a wide range of GMB solutions designed to showcase your business's brilliance within your local community.</p>
            <Link to="gogle-my-business" className='link'><p className="seeMore-btn">See More<AiOutlineDoubleRight /></p></Link>
          </div>
          <div className="insuranceTypeImage">
            <img src={gmb} alt="Home insurance" />
          </div>
        </div>

        <div className="insuranceType">
          <div className="insuranceDesc">
            <Link to="website-development" className='link'><h3>Website Development</h3></Link >
            <p>When it comes to the online landscape of today, your website holds immense importance as it serves as the initial point of contact for potential customers. At Instantly Scaled, we specialize in providing top-notch Website Development services that will truly make a difference. We recognize that a website is more than just an online presence; it is a chance to make a lasting impact, captivate your audience, and fuel the growth of your business.</p>
            <Link to="website-development" className='link'><p className="seeMore-btn">See More<AiOutlineDoubleRight /></p></Link>
          </div>
          <div className="insuranceTypeImage">
            <img src={webdev} alt="Auto insurance" />
          </div>
        </div>

        <div className="insuranceType">
          <div className="insuranceDesc">
            <Link to="website-seo" className='link'><h3>Website SEO </h3></Link>
            <p>In the digital landscape, having a beautiful website is just the first step. To truly succeed online, you need to ensure that your website is easily discoverable by your target audience. That's where our Website SEO (Search Engine Optimization) services at Instantly Scaled come into play. We specialize in optimizing your website to achieve higher rankings in search engines, leading to increased organic traffic and business success.</p>
            <Link to="website-seo" className='link'><p className="seeMore-btn">See More<AiOutlineDoubleRight /></p></Link>
          </div>
          <div className="insuranceTypeImage">
            <img src={seo} alt="" />
          </div>
        </div>

        <div className="insuranceType">
          <div className="insuranceDesc">
            <Link to="ADS" className='link'><h3>ADS Services</h3></Link>

            <p>In today's fiercely competitive online environment, it is essential to ensure that your business reaches the appropriate audience at the perfect moment. This is where ADS comes into play – a potent pay-per-click (PPC) advertising platform. At Instantly Scaled, our expertise lies in crafting and overseeing ADS campaigns that not only generate targeted traffic but also boost conversions, ultimately providing a substantial return on investment (ROI).</p>

            <Link to="ADS" className='link'><p className="seeMore-btn">See More<AiOutlineDoubleRight /></p></Link>
          </div>
          <div className="insuranceTypeImage">
            <img src={ads} alt="" />
          </div>
        </div>

        <div className="insuranceType">
          <div className="insuranceDesc">
            <Link to="web-app-development" className='link'><h3>Web Apps Development</h3></Link>

            <p>In today's fast-paced business environment, having the right tools to streamline operations, engage customers, and enhance user experiences is essential. Enter Web Applications, a powerful solution that can revolutionize the way you do business. At Instantly Scaled, we specialize in developing custom web applications tailored to your unique needs, providing the technology you need to stay ahead in the digital landscape.</p>

            <Link to="web-app-development" className='link'><p className="seeMore-btn">See More<AiOutlineDoubleRight /></p></Link>
          </div>
          <div className="insuranceTypeImage">
            <img src={appdev} alt="" />
          </div>
        </div>

        <div className="insuranceType">
          <div className="insuranceDesc">
            <Link to="mobile-app-development" className='link'><h3>Mobile App Development</h3></Link>

            <p>In today's mobile-driven world, a well-designed mobile app can be a game-changer for your business. It's not just a way to stay connected with your audience; it's an opportunity to provide value, enhance user experiences, and drive engagement. At Instantly Scaled, we specialize in Mobile App Development, creating powerful, user-friendly apps that connect your brand with your audience, whether they're on iOS or Android devices.</p>

            <Link to="mobile-app-development" className='link'><p className="seeMore-btn">See More<AiOutlineDoubleRight /></p></Link>
          </div>
          <div className="insuranceTypeImage">
            <img src={mobileapp} alt="Web Development" />
          </div>
        </div>

      </div>

    </div>
  )
}

export default Services