import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import { Navbar } from './Components/Navbar/Navbar.jsx';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer/Footer';
import Privacy from './Pages/Privacy/Privacy';
import AboutUs from './Pages/About Us/AboutUs';
import ContactUs from './Pages/ContactUs/ContactUs';
import Services from './Pages/Services/Services';
import SingleService from './Components/SingleService/SingleService';
import { ScrollToTop } from './Components/ScrollToTop';
import gmb from './Assets/Services/GoogleBusiness.jpg';
import webdev from './Assets/Services/Website Development.jpg';
import appdev from './Assets/Services/Web_App.jpg';
import mobileapp from './Assets/Services/App Develeopment.jpg';
import ads from './Assets/Services/ADs.jpg';
import seo from './Assets/Services/Web_SEO.jpg';
import './App.css';
import Thanks from './Pages/Thanks/Thanks.jsx';

const serviceContent = {
  gmb: {
    title: "Google my Business",
    description: "If you're seeking to enhance your online visibility and draw in a larger local customer base, then our Google My Business (GMB) services are the answer. Here at Instantly Scaled, we recognize the significance of standing out in local search outcomes and ensuring that potential clients can effortlessly locate your business. That is precisely why we provide a wide range of GMB solutions designed to showcase your business's brilliance within your local community..",
    subTitle: "What is Google My Business?",
    subDesc: "Google My Business is a powerful tool that allows businesses to manage their online presence on Google. It provides essential business information, customer reviews, and map directions—all in one place. When potential customers search for products or services you offer, GMB can significantly impact whether they choose your business or your competitors'.",
  },
  webdev: {
    title: "Website Development",
    description: "If you're seeking to enhance your online visibility and draw in a larger local customer base, then our Google My Business (GMB) services are the answer. Here at Instantly Scaled, we recognize the significance of standing out in local search outcomes and ensuring that potential clients can effortlessly locate your business. That is precisely why we provide a wide range of GMB solutions designed to showcase your business's brilliance within your local community.",
    subTitle: "The Power of Exceptional Web Development",
    subDesc: "A beautifully crafted website goes beyond mere aesthetics; it serves as a potent instrument for establishing credibility, highlighting your brand, and transforming visitors into devoted patrons. Our Website Development solutions are meticulously tailored to produce websites that accomplish precisely these objectives.",
  },
  webseo: {
    title: "Website SEO",
    description: "In the digital landscape, having a beautiful website is just the first step. To truly succeed online, you need to ensure that your website is easily discoverable by your target audience. That's where our Website SEO (Search Engine Optimization) services at Instantly Scaled come into play. We specialize in optimizing your website to achieve higher rankings in search engines, leading to increased organic traffic and business success.",
    subTitle: "Unleash the Power of SEO",
    subDesc: "Search Engine Optimization (SEO) plays a crucial role in enhancing online visibility. It involves optimizing your website to rank higher in search engine results when potential customers search for products or services similar to yours. Our Website SEO services ensure that your website is discovered by the right audience at the opportune moment, making it the secret ingredient for success in the digital realm.",
  },
  ads: {
    title: "ADS Service",
    description: "In the digital landscape, having a beautiful website is just the first step. To truly succeed online, you need to ensure that your website is easily discoverable by your target audience. That's where our Website SEO (Search Engine Optimization) services at Instantly Scaled come into play. We specialize in optimizing your website to achieve higher rankings in search engines, leading to increased organic traffic and business success.",
    subTitle: "The Power of ADS",
    subDesc: "ADS is a game-changer for businesses looking to accelerate their online growth. It allows you to display your ads prominently on search engines and across various online networks, reaching potential customers where they're actively searching for products or services like yours.",
  },
  webapp: {
    title: "Web App Development",
    description: "In the digital landscape, having a beautiful website is just the first step. To truly succeed online, you need to ensure that your website is easily discoverable by your target audience. That's where our Website SEO (Search Engine Optimization) services at Instantly Scaled come into play. We specialize in optimizing your website to achieve higher rankings in search engines, leading to increased organic traffic and business success.",
    subTitle: "The Power of Web Applications",
    subDesc: "Web applications are dynamic, interactive, and accessible from anywhere with an internet connection. They have the potential to automate processes, increase productivity, and offer a seamless experience to your customers and employees alike.",
  },
  mobileapp: {
    title: "Mobile App Development",
    description: "In today's mobile-driven world, a well-designed mobile app can be a game-changer for your business. It's not just a way to stay connected with your audience; it's an opportunity to provide value, enhance user experiences, and drive engagement. At Instantly Scaled, we specialize in Mobile App Development, creating powerful, user-friendly apps that connect your brand with your audience, whether they're on iOS or Android devices.",
    subTitle: "The Power of Mobile Apps",
    subDesc: "Mobile apps offer a direct channel to your customers' smartphones, enabling you to provide convenience, personalized experiences, and real-time updates. They are a vital tool for businesses looking to stay competitive and accessible in today's fast-paced market.",
  }
  // Add more services as needed
};

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path='/services/gogle-my-business' element={<SingleService sisImage={gmb} Title={serviceContent.gmb.title} Desc={serviceContent.gmb.description} subDesc={serviceContent.gmb.subDesc} subTitle={serviceContent.gmb.subTitle} currentPage="google" />} />
          <Route path='/services/website-development' element={<SingleService sisImage={webdev} Title={serviceContent.webdev.title} Desc={serviceContent.webdev.description} subDesc={serviceContent.webdev.subDesc} subTitle={serviceContent.webdev.subTitle} currentPage="website" />} />
          <Route path='/services/website-seo' element={<SingleService sisImage={seo} Title={serviceContent.webseo.title} Desc={serviceContent.webseo.description} subDesc={serviceContent.webseo.subDesc} subTitle={serviceContent.webseo.subTitle} currentPage="seo" />} />
          <Route path='/services/ADS' element={<SingleService sisImage={ads} Title={serviceContent.ads.title} Desc={serviceContent.ads.description} subDesc={serviceContent.ads.subDesc} subTitle={serviceContent.ads.subTitle} currentPage="ads" />} />
          <Route path='/services/web-app-development' element={<SingleService sisImage={appdev} Title={serviceContent.webapp.title} Desc={serviceContent.webapp.description} subDesc={serviceContent.webapp.subDesc} subTitle={serviceContent.webapp.subTitle} currentPage="webapp" />} />
          <Route path='/services/mobile-app-development' element={<SingleService sisImage={mobileapp} Title={serviceContent.mobileapp.title} Desc={serviceContent.mobileapp.description} subDesc={serviceContent.mobileapp.subDesc} subTitle={serviceContent.mobileapp.subTitle} currentPage="mobileapp" />} />
          <Route path='/terms-and-conditions' element={<Privacy />} />
          <Route path='/thanks' element={<Thanks />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
