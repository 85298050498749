import React, { useEffect, useState } from 'react';
import { useForm } from '@formspree/react';
import './ContactUs.css';

const ContactUs = () => {

  //Phone number formatiting
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [submitted, setSubmitted] = useState(false);

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    const formattedInput = input.replace(/\D/g, '');
    if (formattedInput.length <= 10) {

      const formattedPhoneNumber = formattedInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');

      setPhoneNumber(formattedPhoneNumber);
    }
  };

  const [state, handleSubmit] = useForm('mjvqrnnp');

  useEffect(() => {
    if (state.succeeded) {
      window.location.href = '/thanks';
    }
  }, [state.succeeded]);

  return (
    <div className="cpcs">
      {/* <div className="contactTitle">
        <h2>Contact</h2>
      </div> */}
      <div className="contactPageContent">
        <div className="contactPageForm">
          <div className="formDesc">
            <h3>Keep in touch. We'd love to hear from you </h3>
            <h4>If you prefer to send us a message, please complete
              the form below and we will get right back to you.</h4>

          </div>
          <form action="submit" className="cPFrom" onSubmit={handleSubmit}>
            <label htmlFor="" className='contactFormLabel'>Name <span className='red'> *</span></label>
            <input className='cFContent' name="name" type="text" placeholder='Enter your name' required />
            <label htmlFor="" className='contactFormLabel'>Email<span className='red'> *</span></label>
            <input className='cFContent' name="email" type="email" placeholder='Enter your email' required />

            <>
              <label htmlFor="" className='contactFormLabel'>Phone Number<span className='red'> *</span></label>
              <input
                className='cFContent'
                name='phone'
                type="text"
                placeholder="Share the best contact number for our agents to reach you."
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
              {phoneNumber.length < 10 && (
                <span style={{ color: 'red' }}>Please enter a valid 10 digits phone number</span>
              )}
            </>

            <label htmlFor="" className='contactFormLabel'>Message</label>
            <textarea className='cFContent' name="message" id="" cols="30" rows="10" placeholder='Your message...' required></textarea>
            <button className='submitBtn' type='submit'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs