import React from 'react';
import { BiTimeFive, BiLogoFacebookCircle, BiLogoInstagramAlt } from 'react-icons/bi';
import { AiOutlineHome, AiOutlineMail } from 'react-icons/ai';
import { BiSolidPhoneCall} from 'react-icons/bi';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footerContent">
        <div className="fcDesc">
          <h3>INSTANTLY SCALED</h3>
          <p>At Instantly Scaled, we firmly believe in delivering unparalleled excellence. We take pride in our commitment to providing services that are second to none. Our dedication to innovation, expertise, and client-centric solutions sets us apart in the digital landscape. We are not just another digital marketing agency; we are a team of passionate professionals driven by the pursuit of excellence. Choose Instantly Scaled, because we believe in being the best, and we're here to prove it every step of the way.</p>
        </div>
        <div className="fcLinks">
          <div className="resourcesLinks">
            <h4>RESOURCES</h4>
            <Link className='fLink' to="/about_us">About Us</Link>
            <Link className='fLink' to="/services">Services</Link>
            <Link className='fLink' to="/contact_us">Contact Us</Link>
          </div>
          <div className="resourcesLinks con">
            <h4>CONTACT US</h4>
            {/* <p><BiSolidPhoneCall />(516) 200-4649</p> */}
            <p><AiOutlineMail />hello@instantlyscaled.com</p>
            {/* <p><AiOutlineHome />4 New Hyde Park Rd - 4, Franklin Square, NY 11010</p>
            <p><BiTimeFive />9 AM to 6 PM - 7 days a week</p> */}
          </div>
          <div className="socialMedia">
            <BiLogoFacebookCircle className='fsIcon' />
            <BiLogoInstagramAlt className='fsIcon' />
          </div>
        </div>
      </div>

      <div className="copyWrite">
        <div className="copyRightText">
          <p>&#169; 2023 Instantly Sclaed. All Rights Reserved</p>
        </div>
        <div className="policyLinks">
          {/* <Link to="/sitemap" className='plLink'><p>Sitemap</p></Link> */}
          <Link to="/terms-and-conditions" className='plLink'><p>Terms & Conditions</p></Link>
          {/* <Link to="/privacy-policy" className='plLink'><p>Privacy Policy</p></Link> */}
        </div>
      </div>
    </div>
  )
}

export default Footer