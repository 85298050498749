import React from 'react';
import C from '../../Assets/Logos/C.png';
import R from '../../Assets/Logos/R.png';
import python from '../../Assets/Logos/Pyton.png';
import julia from '../../Assets/Logos/Julia.png';
import tenser from '../../Assets/Logos/TensorFlow.png';
import heroImage from '../../Assets/Services/P2.jpg';
import heroImage2 from '../../Assets/Services/P3.jpg';
import heroImage3 from '../../Assets/Services/P5.jpg';
import autoML from '../../Assets/Logos/Auto-MLML-KIT.png';
import caffe from '../../Assets/Logos/Caffe.png'
import googleMl from '../../Assets/Logos/Google-ML-KIT.png';
import keras from '../../Assets/Logos/Keras.png';
import mxnet from '../../Assets/Logos/mxnet.png';
import theano from '../../Assets/Logos/theano.png';
import elImg from '../../Assets/Services/P4.jpg';
import google from '../../Assets/Logos/Official-google-partner.png';
import website from '../../Assets/Services Logos/website.png';
import g from '../../Assets/Services Logos/g.png';
import seo from '../../Assets/Services Logos/seo.png';
import ads from '../../Assets/Services Logos/rnd.png';
import weapp from '../../Assets/Services Logos/webapp.png';
import mobileapp from '../../Assets/Services Logos/mobileapp.png';
import retail from '../../Assets/Logos/Retail.png';
import da from '../../Assets/Logos/Digital-Agency.png';
import saint from '../../Assets/Logos/Saint.png';
import expertise from '../../Assets/Logos/Experties.png';
import trust from '../../Assets/Logos/Trust.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { BsCheck } from 'react-icons/bs';
import { BsFillChatQuoteFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import './Home.css';

const testimonials = [
  {
    quote: "Wow, let me tell you about the incredible impact that Instantly Scaled has had on my business. Their expert knowledge of Google My Business has completely revolutionized my online visibility, resulting in a massive influx of new clients. It's truly like having a hidden gem that gives me an edge in the competitive world of local search.",
    person: "John D.",
  },
  {
    quote: "Their SEO expertise has truly performed miracles for my website. I am now experiencing a significant boost in search engine rankings, resulting in a substantial increase in organic traffic. Instantly Scaled possesses the ability to transform your website into an irresistible attraction for potential customers.",
    person: "David M.",
  },
  {
    quote: "Google Ads? Leave it to Instantly Scaled! They've managed my campaigns brilliantly, delivering a superb return on investment. It's a relief to know my advertising budget is in expert hands.",
    person: "Emily L.",
  },
  {
    quote: "GI'm absolutely thrilled with the incredible results that Instantly Scaled has achieved for my website. Their expertise in SEO has truly worked wonders, and I can't recommend them enough! Thanks to their wizardry with Google My Business, my GMB listing is now consistently at the top of local search results. The way they handle customer reviews has completely transformed my business reputation. It's like magic! Not only am I ranking higher in search engines, but I'm also experiencing a significant increase in organic traffic. Instantly Scaled truly knows how to make your website irresistible to customers.",
    person: "Emma S.",
  },
  {
    quote: "I have come across Instantly Scaled's remarkable expertise in Google My Business. Their unparalleled mastery has transformed my listing from being virtually unnoticed to becoming the top choice in my area. Their adept management of customer reviews and ensuring the easy accessibility of crucial business information has not only attracted a larger clientele but has also significantly enhanced my credibility. I highly recommend their exceptional knowledge and skills in GMB!",
    person: "Robert K.",
  },
  // Add more testimonials as needed
];

const Home = () => {

  const swiperParams = {
    slidesPerView: 2, // Default slides per view (for larger screens)
    // spaceBetween: 30,
    speed: 1200,
    autoplay: {
      delay: 2000, // Adjust the delay between slides (in milliseconds)
      disableOnInteraction: false,
    },
    loop: true,

    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      1440: {
        slidesPerView: 4,
      },
    },
  };

  const swiperImgParams = {
    slidesPerView: 1, // Default slides per view (for larger screens)
    // spaceBetween: 30,
    speed: 800,
    autoplay: {
      delay: 1500, // Adjust the delay between slides (in milliseconds)
      disableOnInteraction: false,
    },
    loop: true,

  };

  const testimonialSwiperParams = {
    slidesPerView: 1, // Default slides per view (for larger screens)
    spaceBetween: 20,
    // navigation: true, 
    speed: 1200,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
  };

  return (
    <div className='homePage'>

      <div className="homeHero">

        <div className="heroRight">
          <Swiper className='heroImgSwiper' {...swiperImgParams} modules={[Autoplay]}>
            <SwiperSlide>
              <div className="heroImg">
                <img src={heroImage} alt="Hero" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="heroImg">
                <img src={heroImage2} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="heroImg">
                <img src={heroImage3} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>

        </div>

        <div className="heroLeft">
          <div className="heroIcons">
            <img src={C} alt="C" />
            <img src={R} alt="R" />
            <img src={python} alt="Python" />
            <img src={julia} alt="Julia" />
            <img src={tenser} alt="Tensor Flow" />
          </div>

          <div className="heroTitle">
            <h1>Take Your Business to the Next Level with AI</h1>
            <p>Harnessing Proven Industry Leaders and Cutting-Edge AI Technology to Assemble Potent Digital Marketing Schemes That Launch Your Company to Greater Altitudes.</p>
          </div>

          <div className="heroBtns">
            <Link to="/contact_us"><button className='contact-btn'>Contact Us Now</button></Link>
          </div>
        </div>

      </div>

      <Swiper className="partnerIcons" {...swiperParams} modules={[Autoplay]} loop={true}>
        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={C} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={R} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={julia} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={autoML} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={caffe} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={googleMl} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={keras} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={mxnet} alt="" />
          </div>
        </SwiperSlide>

        <SwiperSlide className='singleIcon'>
          <div className="pIcon">
            <img src={theano} alt="" />
          </div>
        </SwiperSlide>

      </Swiper>

      <div className="elevateBrand">
        <div className="ebContent">
          <div className="ebcLogo">
            <AiOutlineSafetyCertificate />
          </div>
          <h2>Enhance Your Brand's Visibility with AI-Powered Digital Marketing</h2>
          <p>At Instantly Scaled, our mission revolves around closely observing, comprehending, and evaluating the ever-changing marketing trends. We strive to harness these transformations to benefit our esteemed clients. As pioneers in the digital marketing realm, we offer state-of-the-art AI-powered solutions that encompass various aspects such as customer segmentation, precision targeting, predictive analytics, chatbots, and exceptional customer service. By leveraging our AI Marketing Solutions, we can take your ideas to new heights by driving improved conversions, heightened engagement levels, and a remarkable return on investment.</p>
          <p>Our unwavering commitment to cultivating a harmonious organizational culture extends to our dedicated efforts in nurturing the growth of our team members. This ensures that the results you invest in are not just met but surpassed. You can confidently choose us because we are resolute in delivering on our promises: a streamlined process, enhanced visibility, and unparalleled business performance metrics.</p>
        </div>

        <div className="ebImg">
          <img src={elImg} alt="Elevate" />
        </div>
      </div>

      <div className="whyChooseUs">


        <div className="wcuIcons">
          <div className="wcuTcon">
            <img src={google} alt="" />
          </div>

          <div className="wcuTcon">
            <img src={retail} alt="" />
          </div>

          <div className="wcuTcon">
            <img src={da} alt="" />
          </div>

          <div className="wcuTcon">
            <img src={saint} alt="" />
          </div>

          <div className="wcuTcon">
            <img src={expertise} alt="" />
          </div>

          <div className="wcuTcon">
            <img src={trust} alt="" />
          </div>
        </div>

        <div className="wcuContent">
          <h3>Why Choose Us</h3>
          <h2>Unlocking New Frontiers with AI-Driven Marketing</h2>
          <p>We take pride in our expertise in the digital landscape, providing cutting-edge AI solutions that elevate various aspects of your business. From automation and customer segmentation to targeted marketing and content generation, we offer a comprehensive range of services that ensure your success. With our team of dedicated professionals, we harness the power of cognitive technologies to accelerate your decision-making process.</p>
          <p>As trailblazers in AI-powered marketing, we are committed to delivering unparalleled services to our clients. Our specialized departments are comprised of passionate individuals who strive to add value to your business through the effective use of Artificial Intelligence and digital marketing strategies. By partnering with us, you can expect tailored outcomes that align with your unique business needs, backed by thorough research and meticulous attention to detail.</p>
        </div>
      </div>

      <div className="experience">
        <div className="expContent">
          <div className="chk">
            <BsCheck id='chkIcon' />
          </div>
          <p>Extensive Experience: 5+ years in the industry, delivering proven results</p>
        </div>

        <div className="expContent">
          <div className="chk">
            <BsCheck id='chkIcon' />
          </div>
          <p>Google Partner: We're a trusted Google Partner, ensuring top-notch strategies.</p>
        </div>

        <div className="expContent">
          <div className="chk">
            <BsCheck id='chkIcon' />
          </div>
          <p>Google Partner: We're a trusted Google Partner, ensuring top-notch strategies.</p>
        </div>

        <div className="expContent">
          <div className="chk">
            <BsCheck id='chkIcon' />
          </div>
          <p>Client-Centric: Our transparent approach means your success is our priority.</p>
        </div>
      </div>

      <div className='testimonial'>
        <div className="testimonialDesc">
          <h3>Testimonials</h3>
          <h2>Words From Our Valued Clients</h2>
        </div>
        <div className="testimonail-contnet">

          <Swiper {...testimonialSwiperParams} modules={[A11y, Autoplay]}>
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testimonial-container">


                  <div className="people-quotes">
                    <div className="quote-icon">
                      <BsFillChatQuoteFill />
                    </div>
                    <div className="person-detail">
                      <h4>{testimonial.person}</h4>
                    </div>
                    <div className="quote">
                      <p>{testimonial.quote}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="ourServices">
        <div className="ourServicesTitle">
          <h3>Our Services</h3>
          <h2>Explore Our Services</h2>
        </div>


        <div className="wcuList">
          <div className="wcuListItem">
            <div className="icon">
              <img src={g} alt="Google Business" />
            </div>
            <h4>Google Business Profile</h4>
            <p>Enhance your online visibility and dominate local search results with the power of Google My Business. Become the top choice for potential customers, attract new clients effortlessly, manage customer reviews effectively, and provide essential business details at their fingertips.</p>
          </div>

          <div className="wcuListItem">
            <div className="icon">
              <img src={website} alt="Website Development" />
            </div>
            <h4>Website Development</h4>
            <p>Transform your innovative concepts into an irresistible online presence. Our team of skilled web developers is dedicated to creating visually stunning and user-friendly websites that not only captivate your target audience but also serve as catalysts for achieving remarkable success. We seamlessly blend your website, brand, and business to attract customers effortlessly and generate substantial outcomes.</p>
          </div>

          <div className="wcuListItem">
            <div className="icon">
              <img src={seo} alt="Website SEO" />
            </div>
            <h4>Website SEO</h4>
            <p>Discover the untapped power of your website through the magic of SEO optimization. Enhance your search engine rankings, captivate organic traffic, and guarantee that your website is easily found by your desired audience.</p>
          </div>

          <div className="wcuListItem">
            <div className="icon">
              <img src={ads} alt="ADS" />
            </div>
            <h4>ADS Campaign</h4>
            <p>When it comes to reaching your target audience, timing is key. You want to ensure that your products or services are visible when potential customers are actively searching for them. That's where our Ads campaigns come in. We have crafted our campaigns to not only maximize your return on investment but also deliver tangible and measurable outcomes. With our expertise, you can rest assured that your content will be strategically placed in front of the right eyes at the right time.</p>
          </div>

          <div className="wcuListItem">
            <div className="icon">
              <img src={weapp} alt="Web App Development" />
            </div>
            <h4>Web Apps</h4>
            <p>Looking to optimize your business operations and elevate user experiences? Look no further than our custom web applications. Our team specializes in developing tailored solutions that not only enhance productivity but also drive user engagement to new heights. With our expertise, you can streamline your processes and deliver a seamless digital experience like never before.</p>

          </div>
          <div className="wcuListItem">
            <div className="icon">
              <img src={mobileapp} alt="Mobile App Development" />
            </div>
            <h4>Mobile Apps</h4>
            <p>Looking to expand your reach and captivate your audience while they're on the move? Look no further than our mobile app development services. We specialize in crafting seamless and user-friendly apps that not only keep your customers connected, but also leave them completely satisfied. With our expertise, you can ensure that your app stands out from the crowd and provides a truly exceptional experience for users. So why wait? Take your business to new heights with our top-notch mobile app development services.</p>
          </div>
        </div>
      </div>

      {/* <div className="quickSupport">
        <div className="qsContent">
          <h3>Quick Support</h3>
          <h2>Get in Touch Today</h2>
          <p>Proactively deliver seamless core competencies with scalable. Completely fabricate transparent paradigms.</p>

          <div className="qsOptions">
            <div className="qsOption">
              <div className="qsoIcon">
                <BiSolidPhoneCall style={{ fontSize: 40 }} />
              </div>
              <h3>Call Us</h3>
              <p>Have any questions regarding our services or pricing? Call us to find out more!</p>
            </div>

            <div className="qsOption">
              <div className="qsoIcon mail">
                <AiOutlineMail style={{ fontSize: 40 }} />
              </div>
              <h3>Mail Us</h3>
              <p>Write to us in case of any queries, and we will get back to you as soon as possible.</p>
            </div>
          </div>
        </div>

        <div className="qsForm">
          <form action="" className='qSForm'>
            <span>Fill out the form to avail of our quick consultations. We will get in touch with you as soon as we can!</span>
            <div className="inputFields">
              <input type="text" name='name' placeholder='Name' />
              <input type="text" name='email' placeholder='Email' />
            </div>

            <div className="inputFields">
              <input type="text" name='phone' placeholder='Phone' />
              <input type="text" name='business' placeholder='Business Name' />
            </div>

            <textarea name="message" id="" cols="30" rows="10" placeholder='Message'></textarea>

            <button className='getStartedBtn'>Get Started</button>
          </form>
        </div>
      </div> */}
    </div>
  )
}

export default Home