import React from 'react';
import './Privacy.css';

const Privacy = () => {
	return (
		<div className="privacy">
        <div className="privacy-intro">
          <h2>Terms and Conditions</h2>
        </div>

        <div className="privacy-content">
          <h3>Acceptance of Terms</h3>
          <p>By accessing or using Instantly Scaled ("the Website" or "our Services"), you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our website and services.</p>

          <h3>Changes to Terms</h3>
          <p>Instantly Scaled reserves the right to modify or update these terms and conditions at any time without prior notice. Your continued use of the website and services after changes are made constitutes your acceptance of the revised terms.</p>

          <h3>Privacy Policy</h3>
          <p>Your use of our website and services is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>

          <h3>Services Offered</h3>
          <p>Instantly Scaled offers a range of digital marketing and development services, including but not limited to website SEO, website development, app development, Google Ads, Google Business Profile management, and mobile app development. The specific details and terms of these services will be outlined in individual service agreements.</p>

          <h3>User Conduct</h3>
          <p>You agree not to engage in any of the following activities while using our website and services:</p>
					<ul>
						<li>Violating any applicable laws and regulations.</li>
						<li>Uploading or transmitting any harmful, offensive, or illegal content.</li>
						<li>Interfering with the security, functionality, or availability of the website.</li>
						<li>Attempting to gain unauthorized access to our systems or user accounts.</li>
					</ul>

          <h3>Intellectual Property</h3>
          <p>All content on this website, including text, graphics, logos, images, software, and other materials, is protected by copyright and other intellectual property rights owned by Instantly Scaled or its licensors. You may not use, reproduce, or distribute any of our content without our express permission.</p>

					<h3>Disclaimer of Warranties</h3>
          <p>The information, services, and materials on this website are provided on an "as is" and "as available" basis. Instantly Scaled makes no warranties, expressed or implied, regarding the accuracy, reliability, or suitability of the information and services provided.</p>

					<h3>Limitation of Liability</h3>
          <p>Instantly Scaled, its employees, or its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our website and services.</p>

					<h3>Governing Law</h3>
          <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from these terms and conditions will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>

					<h3>Contact Information</h3>
          <p>If you have any questions or concerns about these terms and conditions, please contact us at [Your Contact Information].</p>
        </div>

				<h4>By using Instantly Scaled's website and services, you acknowledge that you have read, understood, and agreed to these terms and conditions.</h4>
      </div>
	)
}

export default Privacy