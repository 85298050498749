import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <div className='aboutHero'>
        <h1>ABOUT US</h1>
      </div>

      <div className='apContentSec'>
        <div className="contentItem">
          <h2>Who We Are</h2>
          <p>We are a dynamic team of digital marketing enthusiasts, tech-savvy professionals, and AI aficionados. Our collective passion drives us to explore the limitless possibilities that AI offers to transform businesses. With years of experience under our belt, we have mastered the art of blending creativity with technology, delivering solutions that captivate audiences and boost your bottom line.</p>
        </div>

        <div className="contentItem">
          <h2>Our Commitment</h2>
          <p>At Instantly Scaled, our commitment is unwavering. We are committed to your success, and we achieve it by staying at the forefront of digital innovation. Our promise to you is simple: We will harness the power of AI to help you scale your digital presence instantly. We understand that every business is unique, and we tailor our strategies to meet your specific needs.</p>
        </div>

        <div className="midHeading">
          <h2><span className='hlHeading'>Why Choose </span>Instantly Scaled</h2>
        </div>

        <div className="contentItem">
          <h2>AI-Driven Excellence</h2>
          <p>Our secret sauce is AI, and we're not shy about it. We harness cutting-edge AI technology to drive results that consistently exceed expectations.</p>
        </div>

        <div className="contentItem">
          <h2>Full-Spectrum Services</h2>
          <p>From website SEO and development to app development, Google Ads, Google Business Profile management, and mobile app development, we offer a comprehensive suite of services designed to propel your digital growth.</p>
        </div>

        <div className="contentItem">
          <h2>Transparency and Accountability</h2>
          <p>We believe in open and honest communication. Our clients have access to real-time data and reports, ensuring full transparency in our processes and results.</p>
        </div>

        <div className="contentItem">
          <h2>Trusted Partnerships</h2>
          <p>Our success is built on trust, and we take pride in the strong partnerships we've forged with our clients. We view every project as a partnership, and your success is our success. Rest assured, we've got your back every step of the way.</p>
        </div>

        <div className="contentItem">
          <h2>Transparent and Honest</h2>
          <p>We uphold the highest ethical standards in everything we do. Integrity is the foundation of our business, and we are committed to delivering services that are not only effective but also ethical and honest. Your trust is our most valuable asset.</p>
        </div>

        <div className="contentItem">
          <h2>Our Mission</h2>
          <p>Our mission at Instantly Scaled is to empower businesses of all sizes to thrive in the digital world. We aim to demystify the complexities of digital marketing and AI, making them accessible to all. We're not just in the business of scaling; we're in the business of transforming your digital future.</p>
        </div>
      </div>

      <div className="apInvite">
          <p>Join us on this exciting journey as we unlock the potential of AI to transform your digital presence and drive success beyond your wildest dreams. Together, we'll scale new heights, instantly.</p>
        </div>

    </div>
  )
}

export default AboutUs