import React from 'react';
import './SingleService.css';

const SingleService = ({ sisImage, Title, Desc, currentPage, subTitle, subDesc }) => {
  return (
    <div className='singleInsuranceService'>
      <div className="siSHero">

        <div className="siSHeroDesc">
          <h1 className="sishTtitle">{Title}</h1>
          <h3>{Desc}</h3>
        </div>

        <div className="sishImg">
          <img src={sisImage} alt="Insurance Services" className='iSImg' />
        </div>
      </div>

      <div className="insTContent">

        <div className="ourAppraoch">
          <h2>{subTitle}</h2>
          <h3>{subDesc}</h3>
        </div>

        <div className="subServices">
          <h3>Our {Title} Services</h3>

          {currentPage === "google" && <ol>
            <li><h4>GMB Optimization</h4><p className='sContent'>Our team of professionals will work diligently to optimize your GMB listing, ensuring that it is comprehensive, precise, and current. We will enhance your listing with captivating visuals, persuasive descriptions, and carefully chosen keywords to boost its visibility and attract more attention. Rest assured that our experts will leave no stone unturned in their efforts to make your listing stand out from the crowd</p></li>

            <li><h4>Local SEO Integration</h4><p className='sContent'>When it comes to incorporating GMB into your local SEO strategy, we ensure seamless integration. This ensures that not only will your business appear in regular Google searches, but it will also be featured in local map packs, giving you a distinct advantage over competitors.</p></li>

            <li><h4>Review Management</h4><p className='sContent'>Having positive reviews can make a significant impact on your business. Our team is here to assist you in handling customer reviews, encouraging happy customers to share their feedback, and promptly addressing any concerns to uphold an excellent online reputation.</p></li>

            <li><h4>Insightful Reporting</h4><p className='sContent'>We provide you with regular reports on the performance of your GMB listing. You'll see how many people have viewed your listing, requested directions, called your business, and more.</p></li>
          </ol>}

          {currentPage === "website" && <ol>
            <li><h4>Custom Website Design</h4><p className='sContent'>At our company, we firmly believe that there is no universal solution that fits every situation. Our dedicated team of skilled designers and developers will collaborate closely with you to craft a personalized website. This website will not only showcase your brand's identity but also capture your distinct value proposition and strike a chord with your intended audience.</p></li>

            <li><h4>Mobile Optimization</h4><p className='sContent'>In a mobile-centric world, your website must be responsive and user-friendly on all devices. We ensure that your website looks and functions flawlessly on smartphones, tablets, and desktops.</p></li>

            <li><h4>E-commerce Solutions</h4><p className='sContent'>Ready to take your products or services online? We specialize in creating e-commerce websites that provide a seamless shopping experience, secure transactions, and robust inventory management.</p></li>

            <li><h4>Content Management Systems (CMS)</h4><p className='sContent'>Update your website content easily with our user-friendly Content Management Systems.</p></li>
          </ol>}

          {currentPage === "seo" && <ol>
            <li><h4>Keyword Research</h4><p className='sContent'>We start by identifying the most relevant keywords and phrases for your business. This forms the foundation of your SEO strategy, ensuring your website ranks for the terms that matter most.</p></li>

            <li><h4>On-Page Optimization</h4><p className='sContent'>Our experts optimize every aspect of your website, including meta tags, headings, images, and content, to improve search engine rankings and user experience.</p></li>

            <li><h4>Off-Page SEO</h4><p className='sContent'>We build high-quality backlinks, create compelling content, and engage in effective outreach to boost your website's authority and credibility in the eyes of search engines.</p></li>
          </ol>}

          {currentPage === "ads" && <ol>
            <li><h4>Strategic Campaign Setup</h4><p className='sContent'>Our experienced team will create custom ADS campaigns tailored to your business goals, ensuring maximum visibility and relevancy.</p></li>

            <li><h4>Keyword Research and Optimization</h4><p className='sContent'>We conduct in-depth keyword research and optimization to ensure your ads are shown to the right audience, increasing the likelihood of conversions.</p></li>

            <li><h4>Ad Copy and Design</h4><p className='sContent'>Our experts craft compelling ad copy and design eye-catching visuals to capture the attention of your target audience.</p></li>

            <li><h4>Continuous Monitoring and Optimization</h4><p className='sContent'>We continuously monitor the performance of your campaigns and make data-driven adjustments to maximize ROI and minimize wasteful spending.</p></li>
          </ol>}

          {currentPage === "webapp" && <ol>
            <li><h4>Custom Application Design</h4><p className='sContent'>Our team of skilled developers and designers work collaboratively with you to create a tailored web application that aligns perfectly with your business objectives.</p></li>

            <li><h4>Cross-Platform Compatibility</h4><p className='sContent'>We ensure that your web application works flawlessly on all devices, from desktops and laptops to smartphones and tablets, ensuring a consistent user experience.</p></li>

            <li><h4>Scalability and Performance</h4><p className='sContent'>Your web application will be designed with scalability in mind, allowing it to grow with your business while maintaining peak performance.</p></li>

            <li><h4>Security and Data Protection</h4><p className='sContent'>We implement robust security measures to protect your data and ensure that your web application is safe from cyber threats.</p></li>
          </ol>}

          {currentPage === "mobileapp" && <ol>
            <li><h4>. iOS and Android Development</h4><p className='sContent'>Our experienced team of developers is well-versed in both iOS and Android platforms, ensuring that your app reaches a wide audience.</p></li>

            <li><h4>Custom App Design</h4><p className='sContent'>We work closely with you to create a unique, intuitive, and visually appealing app that aligns with your brand identity and user expectations.</p></li>

            <li><h4>User-Centric Functionality</h4><p className='sContent'>We prioritize user experience, ensuring that your app is easy to navigate and provides real value to your audience.</p></li>

            <li><h4>Testing and Quality Assurance</h4><p className='sContent'>Our rigorous testing process guarantees that your app is free of bugs and glitches, providing a seamless experience to your users.</p></li>
          </ol>}


        </div>

        <div className="whyChooseInsurance">
          <h2>Why Choose Instantly Scaled for {Title}</h2>

          {currentPage === "google" && <div className="section" >
            <div className="heading">
              <h3>Expertise</h3>
            </div>
            <div className="content">
              <p>Our team possesses extensive knowledge about GMB and is skilled at harnessing its complete capabilities to benefit your business.</p>
            </div>
          </div>}

          {currentPage === "google" && <div className="section" >
            <div className="heading">
              <h3>Proven Results</h3>
            </div>
            <div className="content">
              <p>We have a track record of helping businesses like yours succeed in local search.</p>
            </div>
          </div>}

          {currentPage === "google" && <div className="section" >
            <div className="heading">
              <h3>Personalized Approach</h3>
            </div>
            <div className="content">
              <p>Your GMB strategy will be tailored to your specific business needs and goals.</p>
            </div>
          </div>}

          {currentPage === "google" && <div className="section" >
            <div className="heading">
              <h3>Transparent Communication</h3>
            </div>
            <div className="content">
              <p>We keep you informed every step of the way and provide clear, actionable insights.</p>
            </div>
          </div>}

          {currentPage === "website" && <div className="section" >
            <div className="heading">
              <h3>Creative Excellence</h3>
            </div>
            <div className="content">
              <p>Our team includes skilled designers and developers who are passionate about creating visually stunning and high-performing websites.</p>
            </div>
          </div>}

          {currentPage === "website" && <div className="section" >
            <div className="heading">
              <h3>User-Centric Approach</h3>
            </div>
            <div className="content">
              <p>We put your users at the forefront, ensuring an intuitive and engaging user experience.</p>
            </div>
          </div>}

          {currentPage === "website" && <div className="section" >
            <div className="heading">
              <h3>Customization</h3>
            </div>
            <div className="content">
              <p>Every website we build is tailored to your specific business needs and objectives.</p>
            </div>
          </div>}

          {currentPage === "website" && <div className="section" >
            <div className="heading">
              <h3>Proven Success</h3>
            </div>
            <div className="content">
              <p>Our portfolio showcases a history of successful website projects across various industries.</p>
            </div>
          </div>}

          {currentPage === "seo" && <div className="section" >
            <div className="heading">
              <h3>Proven Success</h3>
            </div>
            <div className="content">
              <p>Our track record speaks for itself. We've helped businesses of all sizes achieve top rankings and increased organic traffic.</p>
            </div>
          </div>}

          {currentPage === "seo" && <div className="section" >
            <div className="heading">
              <h3>Customized Strategies</h3>
            </div>
            <div className="content">
              <p>We tailor our SEO strategies to your unique business goals and target audience.</p>
            </div>
          </div>}

          {currentPage === "seo" && <div className="section" >
            <div className="heading services">
              <h3>Transparent Reporting</h3>
            </div>
            <div className="content">
              <p>You'll receive regular reports that clearly show your website's progress and the impact on your business.</p>
            </div>
          </div>}

          {currentPage === "seo" && <div className="section" >
            <div className="heading">
              <h3>Ethical Practices</h3>
            </div>
            <div className="content">
              <p>We strictly adhere to white-hat SEO techniques, ensuring the long-term success and integrity of your website.</p>
            </div>
          </div>}

          {currentPage === "ads" && <div className="section" >
            <div className="heading">
              <h3>Expertise</h3>
            </div>
            <div className="content">
              <p>Our team consists of certified ADS professionals who stay updated with the latest industry trends and best practices.</p>
            </div>
          </div>}

          {currentPage === "ads" && <div className="section" >
            <div className="heading">
              <h3>Proven Results</h3>
            </div>
            <div className="content">
              <p>We have a successful track record of driving results for businesses across various industries.</p>
            </div>
          </div>}

          {currentPage === "ads" && <div className="section" >
            <div className="heading">
              <h3>Customized Strategies</h3>
            </div>
            <div className="content">
              <p>We tailor our strategies to your unique business objectives, ensuring a personalized approach to your campaigns.</p>
            </div>
          </div>}

          {currentPage === "ads" && <div className="section" >
            <div className="heading">
              <h3>Transparent Reporting</h3>
            </div>
            <div className="content">
              <p>You'll have access to real-time reports and insights to track the performance of your campaigns.</p>
            </div>
          </div>}

          {currentPage === "webapp" && <div className="section" >
            <div className="heading">
              <h3>Customization</h3>
            </div>
            <div className="content">
              <p>We understand that your business is unique, and we tailor our web applications to meet your specific requirements.</p>
            </div>
          </div>}

          {currentPage === "webapp" && <div className="section" >
            <div className="heading">
              <h3>Support and Maintenance</h3>
            </div>
            <div className="content">
              <p>We provide ongoing support and maintenance to keep your web application running smoothly.</p>
            </div>
          </div>}

          {currentPage === "webapp" && <div className="section" >
            <div className="heading">
              <h3>Client-Centric Approach</h3>
            </div>
            <div className="content">
              <p>Your satisfaction is our priority. We collaborate closely with you throughout the development process to ensure your vision becomes a reality.</p>
            </div>
          </div>}

          {currentPage === "mobileapp" && <div className="section" >
            <div className="heading">
              <h3>Expertise</h3>
            </div>
            <div className="content">
              <p>Our team comprises experienced mobile app developers who are well-versed in the latest technologies and trends.</p>
            </div>
          </div>}

          {currentPage === "mobileapp" && <div className="section" >
            <div className="heading">
              <h3>Cross-Platform Solutions</h3>
            </div>
            <div className="content">
              <p>We create apps for both iOS and Android, maximizing your reach.</p>
            </div>
          </div>}

          {currentPage === "mobileapp" && <div className="section" >
            <div className="heading">
              <h3>Customization</h3>
            </div>
            <div className="content">
              <p>Your app will be tailored to your unique business needs and user requirements.</p>
            </div>
          </div>}

          {currentPage === "mobileapp" && <div className="section" >
            <div className="heading">
              <h3>Continuous Support</h3>
            </div>
            <div className="content">
              <p>We offer ongoing support and updates to keep your app functioning at its best.</p>
            </div>
          </div>}

          {currentPage === "google" && <div className='lifeInsurance'>
            <h3>Your website is your digital storefront—make it unforgettable with Instantly Scaled's Website Development services. Contact us today to get started on your journey to digital excellence.</h3>
          </div>
          }

          {currentPage === "seo" && <div className='lifeInsurance'>
            <h3>Don't let your website get lost in the digital noise. Elevate your online presence with Instantly Scaled's Website SEO services. Contact us today to embark on a journey towards higher rankings, increased visibility, and sustainable growth.</h3>
          </div>
          }

          {currentPage === "ads" && <div className='lifeInsurance'>
            <h3>Ready to take your online advertising to the next level? Trust Instantly Scaled's ADS services to drive targeted traffic, boost conversions, and maximize your ROI. Contact us today to start seeing immediate results.</h3>
          </div>
          }

          {currentPage === "webapp" && <div className='lifeInsurance'>
            <h3>Ready to empower your business with a custom web application? Contact Instantly Scaled today to discuss your needs and start leveraging the benefits of web technology for your business growth.</h3>
          </div>
          }

          {currentPage === "mobileapp" && <div className='lifeInsurance'>
            <h3>Ready to bring your business to the fingertips of your customers? Contact Instantly Scaled today to discuss your mobile app development project and harness the power of mobile technology for your business growth.</h3>
          </div>
          }

        </div>
      </div>
    </div>
  )
}

export default SingleService