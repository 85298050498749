import React from 'react';
import { GoMail } from 'react-icons/go';
import './Thanks.css';
import { Link } from 'react-router-dom';

const Thanks = () => {
  return (
    <>
      <div className='thanks'>
        <div className="msgIcon">
          <GoMail className='msg' />
        </div>

        <div className="thanksText">
          <h2>Thank you for submitting!</h2>
          <p>Your message has been sent!</p>
        </div>

        <div className="gohomeBtn">
          <Link to="/"><button className='contact-btn'>Go Home</button></Link>
        </div>
      </div>
    </>
  )
}

export default Thanks